import React from 'react'
import About from '../Pages/About'
import Home from '../Pages/Home'
import Catalog from '../Pages/Catalog'
import Contact from '../Pages/Contact'
import Purchase from '../Pages/Purchase'
import Services from '../Pages/Services'
import {Routes , Route, Navigate} from "react-router-dom";

const Routers = () => {
  return (
    <Routes >
      <Route path='/' element={<Navigate to ="/Home" />} />
      <Route path='/Home' element={<Home />} />
      <Route path='/About' element={<About />} />
      <Route path='/Catalog' element={<Catalog />} />
      <Route path='/Contact' element={<Contact />} />
      <Route path='/Purchase' element={<Purchase />} />
      <Route path='/Services' element={<Services />} />
      </Routes>
  )
}

export default Routers
