import React from 'react'
import './../../Styles/Header.css'
import { Link } from 'react-router-dom'
import logo from './../../Assest/logo-01.png'

const Header = () => {
  return (
    <>
    <div className="all">
      <div className="logo">
      <Link to="/Home"><img src={logo}/></Link>
      </div>
      <div className="list">
        <ul>
<li><Link to="/">About Us</Link></li>
<li><Link to="/">Catalog</Link></li>
<li><Link to="/">Services</Link></li>
<li><Link to="/">Purchase</Link></li>
<li><Link to="/">Contacts</Link></li>
        </ul>
      </div>
      <div className="but">
        <button>Book A Car</button>
      </div>
    </div>
    
    </>
  )
}

export default Header