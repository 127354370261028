import React from 'react'
import Aboutsection from '../Component/UI/Aboutsection'
import FindCar from '../Component/UI/FindCar'
import Hero from '../Component/UI/Hero'

const Home = () => {
  return (
    <>
      
    <Hero />
    <FindCar />
    <Aboutsection />
    </>
  )
}

export default Home