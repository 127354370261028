import React from 'react'

import "../../Styles/FindCar.css";

import { Form, FormGroup } from "reactstrap";

const FindCar = () => {
  return (
    <>
    <div className="plus">
        <h1>Search Car Auctions</h1>
    
    <Form className="form">
      <div className=" d-flex align-items-center justify-content-between flex-wrap">
        <FormGroup className="form__group">
          <input type="text" placeholder="Brand" required />
        </FormGroup>

        <FormGroup className="form__group">
          <input type="text" placeholder="Model" required />
        </FormGroup>

        <FormGroup className="form__group">
          <input type="date" placeholder="Journey date" required />
        </FormGroup>

        <FormGroup className="form__group">
          <input
            className="journey__time"
            type="time"
            placeholder="Journey time"
            required
          />
        </FormGroup>
        <FormGroup className="select__group">
          <select>
            <option value="ac">AC Car</option>
            <option value="non-ac">Non AC Car</option>
          </select>
        </FormGroup>

        <FormGroup className="form__group">
          <button className="btn find__car-btn">Find Car</button>
        </FormGroup>
      </div>
    </Form>
    </div>
    </>
  )
}

export default FindCar