import React from 'react'

import Slider from "react-slick";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import "../../Styles/Hero.css";

const Hero = () => {
  
    const settings = {
        fade: true,
        speed: 2000,
        autoplaySpeed: 3000,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
      };
      return (
        <Slider {...settings} className="hero__slider">
          <div className="slider__item slider__item-01 mt0">
            <Container>
              <div className="slider__content ">
                <h1>Straight to your house</h1>
                <h4 className="text-light mb-3">Auto From Europe without intermediaries and hidden fees</h4>
                <p>Discover innovative vehicles amnd enjoy the luxury driving experience. Choose the car of your dreams and you won't regret it</p>
                <button className="btn reserve__btn mt-4">
                  <Link to="/Catalog">Pick Up A Car</Link>
                </button>
              </div>
            </Container>
          </div>
    
          <div className="slider__item slider__item-02 mt0">
            <Container>
              <div className="slider__content ">
              <h1>Straight to your house</h1>
                <h4 className="text-light mb-3">Auto From Europe without intermediaries and hidden fees</h4>
                <p>Discover innovative vehicles amnd enjoy the luxury driving experience. Choose the car of your dreams and you won't regret it</p>
                <button className="btn reserve__btn mt-4">
                  <Link to="/Catalog">Pick Up A Car</Link>
                </button>
              </div>
            </Container>
          </div>
    
          <div className="slider__item slider__item-03 mt0">
            <Container>
              <div className="slider__content ">
              <h1>Straight to your house</h1>
                <h4 className="text-light mb-3">Auto From Europe without intermediaries and hidden fees</h4>
                <p>Discover innovative vehicles amnd enjoy the luxury driving experience. Choose the car of your dreams and you won't regret it</p>
                <button className="btn reserve__btn mt-4">
                  <Link to="/Catalog">Pick Up A Car</Link>
                </button>
              </div>
            </Container>
          </div>
        </Slider>
      );
    };
    

export default Hero