import logo from './logo.svg';
import './App.css';
import Layouts from './Component/Layouts/Layouts';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
return <Layouts />
}

export default App;
