import React from 'react'
import './../../Styles/Footer.css'
import { Link } from 'react-router-dom'
import logo from './../../Assest/logo-01.png'
import 'remixicon/fonts/remixicon.css'
const Footer = () => {
  return (
    <>
    <div className="co">
      <div className="bar1">
      <div className="logo1">
        <img src={logo}/>
      </div>
      <div className="social">
      <i class="ri-facebook-circle-fill"></i>
      <i class="ri-instagram-fill"></i>
      <i class="ri-pinterest-fill"></i>
      <i class="ri-telegram-fill"></i>
      </div>     
        <p>Auto Car Europe without intermediaries and hidden Fees</p>
         </div>
      <div className="bar2">
      <ul>
<li><Link>About Us</Link></li>
<li><Link>Catalog</Link></li>
<li><Link>Services</Link></li>
<li><Link>Purchase</Link></li>
<li><Link>Contacts</Link></li>
        </ul>
      </div>
      <div className="bar2">
      <ul>
<li><Link>News</Link></li>
<li><Link>Blogs</Link></li>
<li><Link>Test Drive</Link></li>
<li><Link>Community</Link></li>
        </ul>
      </div>
      <div className="bar3">
        <h1>Get news daily:</h1>
        <input type="text" placeholder='Email' />
        <button>Subscribe</button>
      </div>
    </div>
    </>
  )
}

export default Footer